var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    {
      scopedSlots: _vm._u([
        {
          key: "mds-page-shell-vertical-nav",
          fn: function() {
            return [_c("WorkingDashboardNav")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isLoading
        ? _c("mds-loader")
        : !_vm.isLoading
        ? _c("div", { staticClass: "graphs" }, [
            _c("div", { staticClass: "graph-row" }, [
              _c(
                "div",
                { staticClass: "graph" },
                [
                  _c("WorkingDashboardDonut", {
                    attrs: { title: _vm.articlesTitle, data: _vm.articleValues }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "graph" },
                [
                  _c("WorkingDashboardDonut", {
                    attrs: { title: _vm.videosTitle, data: _vm.videoValues }
                  })
                ],
                1
              )
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }