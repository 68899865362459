var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "mds-label",
        { attrs: { required: _vm.required, text: _vm.label } },
        [
          _c(
            "mds-radio-button",
            {
              attrs: { checked: _vm.isTrue, disabled: _vm.disabled },
              on: { input: _vm.trueSelected }
            },
            [_vm._v(" True ")]
          ),
          _c(
            "mds-radio-button",
            {
              attrs: { checked: _vm.isFalse, disabled: _vm.disabled },
              on: { input: _vm.falseSelected }
            },
            [_vm._v(" False ")]
          ),
          _vm.error
            ? _c(
                "div",
                { staticClass: "mds-input__field-error-wrapper-dp" },
                _vm._l(_vm.errorText, function(text, index) {
                  return _c(
                    "span",
                    { key: index, staticClass: "mds-field-error" },
                    [_vm._v(" " + _vm._s(text) + " ")]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }