var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _c(
        "mds-section",
        { attrs: { title: "Concept Hierarchy" } },
        _vm._l(_vm.newOptionsList, function(options, index) {
          return _c("mds-select", {
            key: index,
            staticClass: "add-filter-select-spacing",
            attrs: { label: "Level " + (index + 1), options: options },
            on: {
              change: function($event) {
                return _vm.selectedChanged(index)
              },
              click: function($event) {
                return _vm.routeToPage(
                  "ConceptHierarchy",
                  {},
                  { conceptId: _vm.selected[index] }
                )
              }
            },
            model: {
              value: _vm.selected[index],
              callback: function($$v) {
                _vm.$set(_vm.selected, index, $$v)
              },
              expression: "selected[index]"
            }
          })
        }),
        1
      ),
      _vm.relatedTerms.length > 0
        ? _c(
            "div",
            [
              _c("span", { staticClass: "bold" }, [
                _vm._v(_vm._s(_vm.selectedText) + " Related Terms:")
              ]),
              _vm._l(_vm.relatedTerms, function(item) {
                return _c("ul", { key: item }, [
                  _c("li", [_vm._v(_vm._s(item))])
                ])
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }