var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    {
      scopedSlots: _vm._u([
        {
          key: "mds-page-shell-vertical-nav",
          fn: function() {
            return [_c("SchemaNav", { attrs: { isLoading: _vm.isLoading } })]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.showSummary
        ? _c(
            "div",
            [
              _c("SchemaSummary", { attrs: { classMap: _vm.classMap } }),
              _c("SchemaGraph", { attrs: { classMap: _vm.classMap } })
            ],
            1
          )
        : _c(
            "div",
            [
              _vm.isLoading || _vm.isLoadingAuth
                ? _c("mds-loader")
                : !_vm.hasAccess
                ? _c("div", [_vm._v("Sorry, you don't have admin access.")])
                : !_vm.isClassExist
                ? _c("div", [
                    _vm._v(
                      " Class " + _vm._s(_vm.activeClass) + " does not exist. "
                    )
                  ])
                : _c(
                    "mds-section",
                    {
                      attrs: {
                        title: _vm.isCreateClass
                          ? "Create A New Class"
                          : _vm.decamelize(_vm.className.value),
                        bold: "",
                        border: "none"
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "mds-section-title-description",
                          fn: function() {
                            return [
                              !_vm.isEditing
                                ? _c("AppIconTooltip", {
                                    attrs: {
                                      icon: "pencil",
                                      tooltip: "edit class"
                                    },
                                    on: { click: _vm.startEditing }
                                  })
                                : _vm._e()
                            ]
                          },
                          proxy: true
                        }
                      ])
                    },
                    [
                      _c(
                        "mds-form",
                        { attrs: { onsubmit: "return false" } },
                        [
                          _vm.isCreateClass
                            ? _c(
                                "mds-input",
                                _vm._b(
                                  {
                                    on: { change: _vm.markDirty },
                                    model: {
                                      value: _vm.className.value,
                                      callback: function($$v) {
                                        _vm.$set(_vm.className, "value", $$v)
                                      },
                                      expression: "className.value"
                                    }
                                  },
                                  "mds-input",
                                  _vm.className,
                                  false
                                )
                              )
                            : _vm._e(),
                          _vm.isEditing
                            ? _c("mds-combo-box", {
                                attrs: {
                                  label: "Data Source",
                                  multiple: "",
                                  dataSet: _vm.dataSourceOptions
                                },
                                on: { input: _vm.markDirty },
                                model: {
                                  value: _vm.dataSource,
                                  callback: function($$v) {
                                    _vm.dataSource = $$v
                                  },
                                  expression: "dataSource"
                                }
                              })
                            : _c("div", [
                                _vm._v(
                                  "Data Source: " +
                                    _vm._s(_vm.dataSource.join(", "))
                                )
                              ]),
                          _c("SchemaDataTable", {
                            key: "Attributes" + _vm.componentKey,
                            ref: "Attributes",
                            attrs: {
                              headers: _vm.attributeHeader,
                              rows: _vm.attributeRows,
                              isEditing: _vm.isEditing,
                              label: "Attributes"
                            },
                            on: { change: _vm.markDirty }
                          }),
                          _c("SchemaDataTable", {
                            key: "Relationships" + _vm.componentKey,
                            ref: "Relationships",
                            attrs: {
                              headers: _vm.relationshipHeader,
                              rows: _vm.relationshipRows,
                              isEditing: _vm.isEditing,
                              label: "Relationships"
                            },
                            on: { change: _vm.markDirty }
                          }),
                          _c(
                            "mds-button-container",
                            [
                              _vm.isEditing
                                ? _c(
                                    "mds-button",
                                    {
                                      attrs: {
                                        variation: "primary",
                                        type: "button",
                                        disabled:
                                          !_vm.isDirty ||
                                          _vm.isSubmiting ||
                                          _vm.isDeleting,
                                        loading: _vm.isSubmiting
                                      },
                                      on: { click: _vm.submit }
                                    },
                                    [_vm._v(" Submit ")]
                                  )
                                : _vm._e(),
                              _vm.isEditing
                                ? _c(
                                    "mds-button",
                                    {
                                      staticClass: "dangerous-button",
                                      attrs: {
                                        variation: "primary",
                                        type: "button",
                                        disabled:
                                          _vm.isSubmiting || _vm.isDeleting,
                                        loading: _vm.isDeleting
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.toggleDelete = true
                                        }
                                      }
                                    },
                                    [_vm._v(" Delete Class ")]
                                  )
                                : _vm._e(),
                              _vm.isEditing
                                ? _c(
                                    "mds-button",
                                    {
                                      attrs: {
                                        disabled:
                                          _vm.isSubmiting || _vm.isDeleting
                                      },
                                      on: { click: _vm.cancelEditing }
                                    },
                                    [_vm._v(" Cancel ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "mds-modal",
                            {
                              attrs: {
                                title: "Warning",
                                width: "600px",
                                "action-required": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "mds-modal-actions",
                                  fn: function() {
                                    return [
                                      _c(
                                        "mds-button-container",
                                        { attrs: { "right-aligned": "" } },
                                        [
                                          _c(
                                            "mds-button",
                                            {
                                              attrs: { variation: "secondary" },
                                              on: {
                                                click: function($event) {
                                                  _vm.toggleDelete = !_vm.toggleDelete
                                                }
                                              }
                                            },
                                            [_vm._v(" No, cancel ")]
                                          ),
                                          _c(
                                            "mds-button",
                                            {
                                              attrs: { variation: "primary" },
                                              on: { click: _vm.deleteClass }
                                            },
                                            [_vm._v(" Yes, continue ")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ]),
                              model: {
                                value: _vm.toggleDelete,
                                callback: function($$v) {
                                  _vm.toggleDelete = $$v
                                },
                                expression: "toggleDelete"
                              }
                            },
                            [
                              _vm._v(
                                " Are you sure you want to permanently delete this class? "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
            ],
            1
          )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }