var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    [
      _c(
        "mds-form",
        [
          _c("mds-textarea", {
            attrs: {
              label: "Custom Query",
              placeholder: "Enter your gremlin query here",
              "microcopy-above":
                'Not familiar with gremlin query? See <a href="http://www.kelvinlawrence.net/book/PracticalGremlin.html" target="_blank"> tutorial</a>.'
            },
            model: {
              value: _vm.query,
              callback: function($$v) {
                _vm.query = $$v
              },
              expression: "query"
            }
          }),
          _c("mds-select", {
            attrs: {
              label: "Predefined Queries",
              placeholder: "select...",
              "microcopy-above": _vm.queryDescription,
              options: _vm.queriesOptions
            },
            on: { change: _vm.selectQuery },
            model: {
              value: _vm.selectedQueryId,
              callback: function($$v) {
                _vm.selectedQueryId = $$v
              },
              expression: "selectedQueryId"
            }
          }),
          _c(
            "mds-button-container",
            [
              _c("AppIconTooltip", {
                attrs: {
                  icon: "plus",
                  tooltip: "create a new query",
                  position: "right-center"
                },
                on: { click: _vm.createQuery }
              }),
              _c("AppIconTooltip", {
                attrs: {
                  icon: "pencil",
                  tooltip: "edit query",
                  position: "right-center",
                  disabled: _vm.selectedQueryId === ""
                },
                on: { click: _vm.editQuery }
              }),
              _c("AppIconTooltip", {
                attrs: {
                  icon: "remove",
                  tooltip: "delete query",
                  position: "right-center",
                  disabled: _vm.selectedQueryId === ""
                },
                on: { click: _vm.deleteQuerySubmit }
              })
            ],
            1
          ),
          _c(
            "mds-button",
            {
              staticClass: "bottom-margin",
              attrs: {
                variation: "primary",
                type: "button",
                loading: _vm.isLoading,
                disabled: _vm.query === "" || _vm.isLoading
              },
              on: { click: _vm.submit }
            },
            [_vm._v(" Submit ")]
          ),
          _c("br"),
          _vm.isLoading
            ? _c("mds-loader")
            : !_vm.isValidResult
            ? _c("mds-empty-state", {
                attrs: { title: "No Results", message: "Start a new search." }
              })
            : _vm.header
            ? _c(
                "div",
                [
                  _c(
                    "mds-switch",
                    {
                      staticClass: "bottom-margin float-right",
                      model: {
                        value: _vm.showJSON,
                        callback: function($$v) {
                          _vm.showJSON = $$v
                        },
                        expression: "showJSON"
                      }
                    },
                    [_vm._v(" Show JSON format ")]
                  ),
                  _c("br"),
                  _vm.showJSON
                    ? _c("pre", [_vm._v(_vm._s(_vm.results))])
                    : _c("mds-data-table", {
                        attrs: {
                          "row-numbers": "",
                          "row-hover": "",
                          "header-configs": _vm.header,
                          "row-data": _vm.results
                        }
                      })
                ],
                1
              )
            : _c(
                "div",
                [
                  _vm._v(
                    " Cannot process results to a table, show JSON format instead: "
                  ),
                  _c("br"),
                  _c("pre", [_vm._v(_vm._s(_vm.results))]),
                  _c("vue-json-pretty", {
                    attrs: { path: "res", data: _vm.results }
                  })
                ],
                1
              )
        ],
        1
      ),
      _c(
        "mds-modal",
        {
          attrs: {
            title: _vm.editModalTitle,
            width: "600px",
            "action-required": ""
          },
          scopedSlots: _vm._u([
            {
              key: "mds-modal-actions",
              fn: function() {
                return [
                  _c(
                    "mds-button-container",
                    { attrs: { "right-aligned": "" } },
                    [
                      _c(
                        "mds-button",
                        {
                          attrs: { variation: "secondary", type: "button" },
                          on: {
                            click: function($event) {
                              _vm.editModal = false
                            }
                          }
                        },
                        [_vm._v(" Cancel ")]
                      ),
                      _c(
                        "mds-button",
                        {
                          attrs: {
                            variation: "primary",
                            type: "button",
                            loading: _vm.isLoading,
                            disabled: _vm.isLoading || !_vm.editValidation
                          },
                          on: { click: _vm.modifyQuerySubmit }
                        },
                        [_vm._v(" Save ")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.editModal,
            callback: function($$v) {
              _vm.editModal = $$v
            },
            expression: "editModal"
          }
        },
        [
          _c(
            "mds-form",
            { staticClass: "long-form", attrs: { onsubmit: "return false" } },
            [
              _c("mds-input", {
                attrs: { label: "Name", required: "" },
                model: {
                  value: _vm.newText,
                  callback: function($$v) {
                    _vm.newText = $$v
                  },
                  expression: "newText"
                }
              }),
              _c("mds-input", {
                attrs: { label: "Description", required: "" },
                model: {
                  value: _vm.newDescription,
                  callback: function($$v) {
                    _vm.newDescription = $$v
                  },
                  expression: "newDescription"
                }
              }),
              _c("mds-textarea", {
                attrs: { label: "Query", required: "" },
                model: {
                  value: _vm.newQuery,
                  callback: function($$v) {
                    _vm.newQuery = $$v
                  },
                  expression: "newQuery"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }