var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mds-page-shell",
    {
      attrs: {
        logo: require("@/assets/Mstar-logo-50px-taxonomy.svg"),
        "logo-responsive": require("@/assets/Mstar-logo-50px.svg"),
        "logo-href": "/",
        "logo-alt": "Home",
        "hidden-menu": "",
        sticky: ""
      },
      scopedSlots: _vm._u(
        [
          {
            key: "mds-page-shell-masthead-left",
            fn: function() {
              return [_vm.showSearchField ? _c("TermSearchField") : _vm._e()]
            },
            proxy: true
          },
          {
            key: "mds-page-shell-masthead-right",
            fn: function() {
              return [
                _c("div", [
                  !_vm.authenticated
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            staticClass:
                              "mds-masthead--hide-wide mds-page-shell__menu-button--responsive"
                          },
                          [
                            _c("mds-button", {
                              attrs: {
                                variation: "icon-only",
                                icon: "person",
                                type: "button"
                              },
                              on: { click: _vm.login }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "mds-masthead--show-wide" },
                          [
                            _c("mds-button", {
                              attrs: {
                                variation: "flat",
                                icon: "person",
                                "flat-button-icon-size": "medium",
                                text: "Sign In",
                                type: "button"
                              },
                              on: { click: _vm.login }
                            })
                          ],
                          1
                        )
                      ])
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "mds-masthead--hide-wide" },
                          [
                            _c("mds-button", {
                              attrs: {
                                variation: "icon-only",
                                icon: "person",
                                type: "button"
                              },
                              on: {
                                click: function($event) {
                                  _vm.userPopover = !_vm.userPopover
                                }
                              }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "mds-masthead--show-wide mds-page-shell__menu-button"
                          },
                          [
                            _c("mds-button", {
                              attrs: {
                                id: "popover-trigger",
                                variation: "flat",
                                icon: "person",
                                "icon-right": "caret-down",
                                "flat-button-icon-size": "medium",
                                text: _vm.user.given_name,
                                type: "button"
                              },
                              on: {
                                click: function($event) {
                                  _vm.userPopover = !_vm.userPopover
                                }
                              }
                            }),
                            _c(
                              "mds-popover",
                              {
                                attrs: {
                                  "triggered-by": "popover-trigger",
                                  position: "bottom-left",
                                  width: "200px",
                                  title: "My Account"
                                },
                                model: {
                                  value: _vm.userPopover,
                                  callback: function($$v) {
                                    _vm.userPopover = $$v
                                  },
                                  expression: "userPopover"
                                }
                              },
                              [
                                _c("mds-button", {
                                  attrs: {
                                    variation: "secondary",
                                    text: "Sign Out",
                                    type: "button",
                                    autofocus: false
                                  },
                                  on: { click: _vm.logout }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "mds-page-shell-hidden-nav",
            fn: function() {
              return [
                _c("AppHiddenMenu"),
                _c(
                  "div",
                  {
                    staticClass:
                      "mds-masthead--hide-wide spacing-btw-hidden-vertical"
                  },
                  [_vm._t("mds-page-shell-vertical-nav")],
                  2
                )
              ]
            },
            proxy: true
          },
          {
            key: "mds-page-shell-vertical-nav",
            fn: function() {
              return [_vm._t("mds-page-shell-vertical-nav")]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _c(
        "div",
        { staticClass: "mds-page-shell__content-spacing" },
        [_vm._t("default")],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }