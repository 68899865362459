var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    [
      _vm.isLoading || _vm.isLoadingAuth
        ? _c("mds-loader")
        : !_vm.hasAccess
        ? _c("div", [_vm._v("Sorry, you don't have write access.")])
        : _c(
            "div",
            [
              _c(
                "mds-form",
                { attrs: { onsubmit: "return false" } },
                [
                  _c("mds-select", {
                    attrs: {
                      name: "taxonomyClass",
                      label: "Primary Classification (Class)",
                      options: _vm.classOptions
                    },
                    on: { change: _vm.classSelected },
                    model: {
                      value: _vm.termObject["label"],
                      callback: function($$v) {
                        _vm.$set(_vm.termObject, "label", $$v)
                      },
                      expression: "termObject['label']"
                    }
                  }),
                  _vm._l(_vm.attributeSchema, function(
                    attribute,
                    attributeIdx
                  ) {
                    return _c(
                      "TermEditAttribute",
                      _vm._b(
                        {
                          key: "" + attribute.name + attributeIdx,
                          ref: "attributes",
                          refInFor: true,
                          on: {
                            change: function($event) {
                              return _vm.attributeChanged(
                                attribute.name,
                                $event
                              )
                            }
                          }
                        },
                        "TermEditAttribute",
                        attribute,
                        false
                      )
                    )
                  }),
                  _vm._l(_vm.relationshipSchema, function(relationship) {
                    return _c(
                      "TermEditRelationship",
                      _vm._b(
                        {
                          key:
                            "" + relationship.name + relationship.targetClass,
                          ref: "relationships",
                          refInFor: true,
                          on: {
                            change: function($event) {
                              return _vm.relationshipChanged(
                                relationship,
                                $event
                              )
                            }
                          }
                        },
                        "TermEditRelationship",
                        relationship,
                        false
                      )
                    )
                  }),
                  _c(
                    "mds-button-container",
                    [
                      _c(
                        "mds-button",
                        {
                          attrs: {
                            variation: "primary",
                            type: "button",
                            disabled: !_vm.isDirty || _vm.isSubmiting,
                            loading: _vm.isSubmiting
                          },
                          on: { click: _vm.submit }
                        },
                        [_vm._v(" Submit ")]
                      ),
                      _c(
                        "mds-button",
                        {
                          attrs: { disabled: _vm.isSubmiting },
                          on: { click: _vm.cancelEditing }
                        },
                        [_vm._v(" Cancel ")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }