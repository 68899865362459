var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _c(
        "mds-section",
        {
          attrs: { title: "All Classes" },
          scopedSlots: _vm._u([
            {
              key: "mds-section-actions",
              fn: function() {
                return [
                  _c("AppIconTooltip", {
                    staticClass: "mds-section-actions-spacing",
                    attrs: { icon: "plus", tooltip: "create a class" },
                    on: {
                      click: function($event) {
                        return _vm.routeToPage("ClassCreate", {}, {})
                      }
                    }
                  })
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _c(
            "mds-list-group",
            [_vm.isLoading ? _c("mds-loader") : _vm._e()],
            1
          ),
          _c(
            "mds-list-group",
            { attrs: { variation: "navigation" } },
            _vm._l(_vm.navList, function(item) {
              return _c("mds-list-group-item", {
                key: item,
                attrs: {
                  active: item === _vm.activeItem,
                  text: _vm.decamelize(item)
                },
                on: {
                  "mds-list-item-clicked": function($event) {
                    return _vm.routeToPage("ClassView", {}, { className: item })
                  }
                }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }