var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-spacing" },
    [
      _c(
        "div",
        { staticClass: "label-spacing" },
        [
          _c("span", { staticClass: "bold" }, [
            _vm._v(_vm._s(_vm.description))
          ]),
          _c("mds-button", {
            staticClass: "float-right",
            attrs: {
              variation: "icon-only",
              icon: "remove",
              size: "small",
              type: "button"
            },
            on: { click: _vm.removeFilter }
          })
        ],
        1
      ),
      _vm.type === "relationships"
        ? _c(
            "div",
            [
              _c("TermSearchField", {
                staticClass: "searchbox-spacing",
                attrs: {
                  size: "small",
                  onSelectRoute: false,
                  labelFilter: _vm.label
                },
                on: { selected: _vm.selectedSearch }
              }),
              _vm.name === "isAbout"
                ? _c(
                    "mds-checkbox",
                    {
                      staticClass: "label-spacing",
                      model: {
                        value: _vm.searchAllNarrowerTerms,
                        callback: function($$v) {
                          _vm.searchAllNarrowerTerms = $$v
                        },
                        expression: "searchAllNarrowerTerms"
                      }
                    },
                    [_vm._v(" Add all narrower terms ")]
                  )
                : _vm._e(),
              _vm.name === "isAbout"
                ? _c(
                    "mds-checkbox",
                    {
                      staticClass: "label-spacing",
                      model: {
                        value: _vm.searchRelatedTerms,
                        callback: function($$v) {
                          _vm.searchRelatedTerms = $$v
                        },
                        expression: "searchRelatedTerms"
                      }
                    },
                    [_vm._v(" Add related terms ")]
                  )
                : _vm._e(),
              _vm._l(_vm.searchList, function(item, index) {
                return _c(
                  "mds-tag-container",
                  { key: index },
                  [
                    _c(
                      "mds-tag",
                      {
                        attrs: { removable: "", "on-light": true },
                        on: {
                          "mds-tag-removed": function($event) {
                            return _vm.tagRemoved(index)
                          }
                        }
                      },
                      [_vm._v(_vm._s(item.name))]
                    )
                  ],
                  1
                )
              }),
              _vm.isSearchingNarrowerTerms ? _c("mds-loader") : _vm._e()
            ],
            2
          )
        : _vm.type === "DateTime"
        ? _c(
            "div",
            [
              _c(
                "AppDatePicker",
                _vm._b(
                  { on: { selected: _vm.selectedStartDate } },
                  "AppDatePicker",
                  _vm.startDate,
                  false
                )
              ),
              _vm._v(" - "),
              _c(
                "AppDatePicker",
                _vm._b(
                  { on: { selected: _vm.selectedEndDate } },
                  "AppDatePicker",
                  _vm.endDate,
                  false
                )
              )
            ],
            1
          )
        : _vm.type === "Bool"
        ? _c(
            "mds-switch",
            {
              on: { change: _vm.switched },
              model: {
                value: _vm.switchValue,
                callback: function($$v) {
                  _vm.switchValue = $$v
                },
                expression: "switchValue"
              }
            },
            [_vm._v(_vm._s(_vm.switchText))]
          )
        : _vm.type === "Free Text" && _vm.options !== undefined
        ? _c("mds-combo-box", {
            attrs: {
              "hidden-label": "",
              label: "Hidden Label",
              multiple: "",
              dataSet: _vm.options
            },
            on: { input: _vm.selectedComboBox },
            model: {
              value: _vm.selectValue,
              callback: function($$v) {
                _vm.selectValue = $$v
              },
              expression: "selectValue"
            }
          })
        : _c("mds-input", {
            attrs: { "hidden-label": "", label: "Hidden Label" },
            on: { change: _vm.input },
            model: {
              value: _vm.inputValue,
              callback: function($$v) {
                _vm.inputValue = $$v
              },
              expression: "inputValue"
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }