var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.label
        ? _c("mds-label", {
            staticClass: "label-margin-dp",
            attrs: { required: _vm.required, text: _vm.label }
          })
        : _vm._e(),
      _c("datepicker", {
        attrs: {
          format: "MM/dd/yyyy",
          typeable: true,
          "calendar-class": "narrow-calendar",
          "input-class": _vm.inputClass,
          value: _vm.value,
          disabled: _vm.disabled,
          placeholder: _vm.placeholder
        },
        on: { selected: _vm.selected }
      }),
      _vm.error
        ? _c(
            "div",
            { staticClass: "mds-input__field-error-wrapper-dp" },
            _vm._l(_vm.errorText, function(text, index) {
              return _c(
                "span",
                { key: index, staticClass: "mds-field-error" },
                [_vm._v(" " + _vm._s(text) + " ")]
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }