var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "400px" } },
    [
      _c("mds-section", {
        attrs: { title: "Database Summary", bold: "", border: "none" },
        scopedSlots: _vm._u([
          {
            key: "mds-section-actions",
            fn: function() {
              return [
                _c("AppIconTooltip", {
                  staticClass: "mds-section-actions-spacing",
                  attrs: {
                    icon: "refresh",
                    tooltip: "refresh count",
                    disabled: _vm.isLoading
                  },
                  on: {
                    click: function($event) {
                      return _vm.load(true)
                    }
                  }
                })
              ]
            },
            proxy: true
          }
        ])
      }),
      _vm.isLoading
        ? _c("mds-loader")
        : _vm.rows.length == 0
        ? _c("div", [_vm._v(" There are no classes in the database. ")])
        : _c("mds-data-table", {
            attrs: {
              "row-numbers": "",
              "row-hover": "",
              "header-configs": _vm.headers,
              "row-data": _vm.rows
            },
            on: {
              "mds-data-table-sort-change": function($event) {
                return _vm.onSort($event)
              }
            }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }