var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mds-notification-container",
    _vm._l(_vm.list, function(item) {
      return _c(
        "mds-notification",
        {
          key: item.id,
          attrs: {
            size: "small",
            title: item.title,
            variation: item.variation,
            dismissDelay: item.dismissDelay
          },
          on: {
            "mds-notification-dismissed": function($event) {
              return _vm.removeItem(item.id)
            }
          },
          scopedSlots: _vm._u(
            [
              item.action
                ? {
                    key: "mds-notification-custom-action",
                    fn: function() {
                      return [
                        _c(
                          "mds-button-container",
                          [
                            _c("mds-button", {
                              staticClass: "dangerous-button",
                              attrs: {
                                type: "button",
                                variation: "primary",
                                size: "small",
                                text: item.actionText,
                                disabled: _vm.isTakingAction
                              },
                              on: {
                                click: function($event) {
                                  return _vm.takeAction(item)
                                }
                              }
                            }),
                            _c("mds-button", {
                              attrs: {
                                type: "button",
                                variation: "secondary",
                                text: "Cancel",
                                size: "small",
                                disabled: _vm.isTakingAction
                              },
                              on: {
                                click: function($event) {
                                  return _vm.removeItem(item.id)
                                }
                              }
                            })
                          ],
                          1
                        )
                      ]
                    },
                    proxy: true
                  }
                : null
            ],
            null,
            true
          )
        },
        [_c("span", { domProps: { innerHTML: _vm._s(item.message) } })]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }