var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("label", { staticClass: "mds-label table-label" }, [
        _c("div", { staticClass: "mds-label__text table-label-text" }, [
          _vm._v(" " + _vm._s(_vm.label) + " ")
        ])
      ]),
      _vm.isEditing
        ? _c("AppIconTooltip", {
            attrs: { icon: "plus", tooltip: "add a row" },
            on: { click: _vm.addRow }
          })
        : _vm._e(),
      _vm.rows_.length > 0
        ? _c("mds-data-table", {
            staticClass: "table-margin table-cell-wrap",
            attrs: {
              "row-hover": "",
              "header-configs": _vm.headers_,
              "row-data": _vm.rows_,
              id: _vm.label
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "body-cell",
                  fn: function(cellData) {
                    return [
                      cellData.headerConfig.fieldName.includes("ActionButton")
                        ? _c("mds-button", {
                            staticClass: "table-button",
                            attrs: {
                              variation: "icon-only",
                              icon:
                                cellData.rowData[
                                  cellData.headerConfig.fieldName
                                ]
                            },
                            on: {
                              click: function($event) {
                                return _vm.actionButtonClicked(
                                  cellData.headerConfig.fieldName,
                                  cellData
                                )
                              }
                            }
                          })
                        : typeof cellData.rowData[
                            cellData.headerConfig.fieldName
                          ] === "boolean"
                        ? _c("span", [
                            cellData.rowData[cellData.headerConfig.fieldName]
                              ? _c("span", [_vm._v(" √ ")])
                              : _vm._e()
                          ])
                        : _vm._e()
                    ]
                  }
                }
              ],
              null,
              false,
              3734709023
            )
          })
        : _vm._e(),
      _c("SchemaAttribute", {
        attrs: {
          toggle: _vm.label === "Attributes" && _vm.toggleEdit,
          isNew: _vm.isNewLine,
          value: _vm.curLine
        },
        on: { cancel: _vm.cancelEdit, save: _vm.saveEdit }
      }),
      _c("SchemaRelationship", {
        attrs: {
          toggle: _vm.label !== "Attributes" && _vm.toggleEdit,
          isNew: _vm.isNewLine,
          value: _vm.curLine
        },
        on: { cancel: _vm.cancelEdit, save: _vm.saveEdit }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }