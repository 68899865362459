var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("path", {
      attrs: {
        d: _vm.getPath(_vm.link),
        stroke: _vm.linkStroke,
        fill: "none",
        "stroke-width": _vm.linkStrokeWidth
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }