var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("mds-select", {
          attrs: { label: "Select Language", options: _vm.locales },
          model: {
            value: _vm.selectedLocale,
            callback: function($$v) {
              _vm.selectedLocale = $$v
            },
            expression: "selectedLocale"
          }
        }),
        _c("mds-input", {
          attrs: { label: "Enter Translated Term" },
          on: {
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              ) {
                return null
              }
              $event.preventDefault()
              return _vm.addTranslation.apply(null, arguments)
            }
          },
          model: {
            value: _vm.translatedTerm,
            callback: function($$v) {
              _vm.translatedTerm = $$v
            },
            expression: "translatedTerm"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }