<template>
  <div>
    <div class="container">
      <mds-select
        label="Select Language"
        :options="locales"
        v-model="selectedLocale"
      >
      </mds-select>
      <mds-input
        v-model="translatedTerm"
        label="Enter Translated Term"
        @keydown.enter.prevent="addTranslation"
      />
    </div>
  </div>
</template>
<script>
import MdsSelect from '@mds/select';
import Request from '@/js/request.js';
import MdsInput from '@mds/input';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import _ from 'underscore';

export default {
  name: 'TranslationAdd',
  components: {
    MdsSelect,
    MdsInput,
    MdsButton,
    MdsButtonContainer,
  },
  data() {
    return {
      locales: [],
      selectedLocale: 'de-DE',
      translatedTerm: '',
    };
  },
  mounted() {
    this.loadLocales();
  },
  methods: {
    async loadLocales() {
      let response = await Request.get('/schema/article', this.ignoreCache);
      response['attributes'].forEach(element => {
        if (element.name == 'locale') {
          let locals = element['predefinedList'].split(',');
          for (let local in locals) {
            this.locales.push({
              value: locals[local],
              text: locals[local],
            });
          }
        }
      });
      this.locales = _.sortBy(this.locales, 'value');
    },
    addTranslation() {
      let translationPair = this.selectedLocale + ';' + this.translatedTerm;
      this.$emit('translation-add', translationPair);
      this.translatedTerm = '';
    },
  },
};
</script>
<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 5px;
}
</style>
