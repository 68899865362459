var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mds-modal",
    {
      attrs: {
        title: _vm.isNew ? "Add Relationship" : "Edit Relationship",
        width: "600px",
        "action-required": ""
      },
      scopedSlots: _vm._u([
        {
          key: "mds-modal-actions",
          fn: function() {
            return [
              _c(
                "mds-button-container",
                { attrs: { "right-aligned": "" } },
                [
                  _c(
                    "mds-button",
                    {
                      attrs: { variation: "secondary", type: "button" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "mds-button",
                    {
                      attrs: { variation: "primary", type: "button" },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.toggle,
        callback: function($$v) {
          _vm.toggle = $$v
        },
        expression: "toggle"
      }
    },
    [
      _c(
        "mds-form",
        { attrs: { onsubmit: "return false" } },
        [
          _c(
            "mds-input",
            _vm._b(
              {
                attrs: { disabled: !_vm.isNew },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  }
                },
                model: {
                  value: _vm.name.value,
                  callback: function($$v) {
                    _vm.$set(_vm.name, "value", $$v)
                  },
                  expression: "name.value"
                }
              },
              "mds-input",
              _vm.name,
              false
            )
          ),
          _c(
            "mds-input",
            _vm._b(
              {
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  },
                  change: _vm.assignName
                },
                model: {
                  value: _vm.description.value,
                  callback: function($$v) {
                    _vm.$set(_vm.description, "value", $$v)
                  },
                  expression: "description.value"
                }
              },
              "mds-input",
              _vm.description,
              false
            )
          ),
          _c(
            "mds-select",
            _vm._b(
              {
                model: {
                  value: _vm.targetClass.value,
                  callback: function($$v) {
                    _vm.$set(_vm.targetClass, "value", $$v)
                  },
                  expression: "targetClass.value"
                }
              },
              "mds-select",
              _vm.targetClass,
              false
            )
          ),
          _c(
            "mds-checkbox",
            {
              staticClass: "check-box-margin",
              model: {
                value: _vm.required.value,
                callback: function($$v) {
                  _vm.$set(_vm.required, "value", $$v)
                },
                expression: "required.value"
              }
            },
            [_vm._v(" Required ")]
          ),
          _c(
            "mds-checkbox",
            {
              staticClass: "check-box-margin",
              model: {
                value: _vm.readOnly.value,
                callback: function($$v) {
                  _vm.$set(_vm.readOnly, "value", $$v)
                },
                expression: "readOnly.value"
              }
            },
            [_vm._v(" Read Only ")]
          ),
          _c(
            "mds-input",
            _vm._b(
              {
                attrs: { disabled: !_vm.isNew },
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  }
                },
                model: {
                  value: _vm.nameInTarget.value,
                  callback: function($$v) {
                    _vm.$set(_vm.nameInTarget, "value", $$v)
                  },
                  expression: "nameInTarget.value"
                }
              },
              "mds-input",
              _vm.nameInTarget,
              false
            )
          ),
          _c(
            "mds-input",
            _vm._b(
              {
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  },
                  change: _vm.assignNameInTarget
                },
                model: {
                  value: _vm.descriptionInTarget.value,
                  callback: function($$v) {
                    _vm.$set(_vm.descriptionInTarget, "value", $$v)
                  },
                  expression: "descriptionInTarget.value"
                }
              },
              "mds-input",
              _vm.descriptionInTarget,
              false
            )
          ),
          _c(
            "mds-checkbox",
            {
              staticClass: "check-box-margin",
              model: {
                value: _vm.requiredInTarget.value,
                callback: function($$v) {
                  _vm.$set(_vm.requiredInTarget, "value", $$v)
                },
                expression: "requiredInTarget.value"
              }
            },
            [_vm._v(" Required (In Target Class) ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }