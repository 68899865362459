<template>
  <app-masthead :showSearchField="false">
    <template v-slot:mds-page-shell-vertical-nav>
      <nav id="advanced-search">
        <mds-section title="Advanced Search">
          <template v-slot:mds-section-actions>
            <AppIconTooltip
              class="mds-section-actions-spacing"
              icon="refresh"
              tooltip="reset filters"
              @click="refreshSearchFilter"
            />
          </template>
          <mds-loader v-if="!isLoaded"></mds-loader>
          <mds-form v-else>
            <mds-combo-box
              label="Class"
              multiple
              v-model="activeClasses"
              :dataSet="classOptions"
            ></mds-combo-box>
            <AdvancedSearchFilter
              v-for="(item, index) in activeFilters"
              :key="`${item.name}${index}`"
              v-bind="item"
              @update="updateFilterValue(index, $event)"
              @remove="removeFilter(index)"
            />
            <mds-select
              class="add-filter-select-spacing"
              label=""
              placeholder="Add a filter"
              :hiddenLabel="true"
              :options="filterOptions"
              @change="selectFilter"
            ></mds-select>
            <mds-button
              text="Search"
              type="button"
              variation="primary"
              @click="routeToNewSearch"
            >
            </mds-button>
          </mds-form>
        </mds-section>
      </nav>
    </template>
    <mds-layout-grid>
      <mds-row
        class="search-row"
        align-horizontal="space-around"
        align-vertical="center"
      >
        <mds-col :cols="11">
          <mds-search-field
            v-model="keyWord"
            @keyup.enter.native="routeToNewSearch"
          ></mds-search-field>
        </mds-col>
        <mds-col :cols="1">
          <AppIconTooltip
            icon="download"
            tooltip="download results"
            position="bottom-left"
            @click="showDownloadModal"
            :disabled="results === null || results.length == 0"
          />
        </mds-col>
      </mds-row>
    </mds-layout-grid>
    <mds-modal
      title="Download results in csv"
      v-model="downloadModal"
      :width="'600px'"
      action-required
    >
      <template v-slot:mds-modal-actions>
        <mds-button-container right-aligned>
          <mds-button
            @click="downloadModal = false"
            variation="secondary"
            type="button"
          >
            Cancel
          </mds-button>
          <mds-button
            @click="downloadResults"
            variation="primary"
            type="button"
            :loading="isDownloading"
            :disabled="isDownloading"
          >
            Download
          </mds-button>
        </mds-button-container>
      </template>
      <mds-form class="long-form" onsubmit="return false">
        <div>
          Select the fields to be exported:
        </div>
        <br />
        <mds-combo-box
          label="Attributes"
          multiple
          v-model="downloadAttr"
          :dataSet="downloadAttrOptions"
        ></mds-combo-box>
        <mds-combo-box
          label="Relationships"
          multiple
          v-model="downloadRela"
          :dataSet="downloadRelaOptions"
        ></mds-combo-box>
      </mds-form>
    </mds-modal>
    <mds-loader v-if="isSearching"></mds-loader>
    <div v-else>
      <mds-list-group class="mds-list-group-spacing">
        <!-- TODO: 2 Emphasize the matched key words in results -->
        <AppTermSummary
          v-for="(item, index) in results"
          :key="index"
          :termObject="item"
          @click="routeToTerm(item)"
        />
      </mds-list-group>
      <mds-pagination
        v-if="!isSearching && count > 0"
        show-items-info
        show-items-select
        :page="currentPage"
        :total-items="count"
        :pageSize="pageSize"
        @mds-pagination-page-changed="pageChanged"
      ></mds-pagination>
      <mds-empty-state
        v-if="!isSearching && count == 0"
        title="No Results"
        message="Start a new search."
      ></mds-empty-state>
    </div>
  </app-masthead>
</template>

<script>
import AdvancedSearchFilter from './AdvancedSearchFilter.vue';
import AppIconTooltip from '@/components/elements/AppIconTooltip.vue';
import AppMasthead from '@/components/elements/AppMasthead.vue';
import AppTermSummary from '@/components/elements/AppTermSummary.vue';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsComboBox from '@mds/combo-box';
import MdsEmptyState from '@mds/empty-state';
import MdsForm from '@mds/form';
import { MdsListGroup } from '@mds/list-group';
import MdsLoader from '@mds/loader';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsModal from '@mds/modal';
import MdsPagination from '@mds/pagination';
import MdsSearchField from '@mds/search-field';
import MdsSection from '@mds/section';
import MdsSelect from '@mds/select';
import Constants from '@/js/constants.js';
import Utils from '@/js/utils.js';

export default {
  name: 'AdvancedSearch',
  components: {
    AdvancedSearchFilter,
    AppIconTooltip,
    AppMasthead,
    AppTermSummary,
    MdsButton,
    MdsButtonContainer,
    MdsComboBox,
    MdsCol,
    MdsEmptyState,
    MdsForm,
    MdsLayoutGrid,
    MdsListGroup,
    MdsLoader,
    MdsModal,
    MdsPagination,
    MdsRow,
    MdsSearchField,
    MdsSection,
    MdsSelect,
  },
  mixins: [Constants, Utils],
  async mounted() {
    this.advancedSearch();
    this.loadSchema();
  },
  data() {
    return {
      //For Search Box
      keyWord: this.$route.query['name.like'],
      //For results
      results: [],
      count: 0,
      isSearching: false,
      isDownloading: false,
      downloadModal: false,
      downloadAttr: [],
      downloadRela: [],
      downloadAttrOptions: [],
      downloadRelaOptions: [],
      //For Nav
      classMap: {},
      isLoaded: false,
      filterOptions: [],
      classOptions: [],
      activeClasses: [],
      activeFilters: [],
      relationshipMap: [],
      attributeMap: [],
      defaultFilter: {
        article: ['authors', 'isAbout', 'publishDate', 'locale'],
        video: ['authors', 'isAbout', 'publishDate', 'locale'],
      },
      pageSize: 20,
    };
  },
  methods: {
    async loadSchema() {
      this.isLoaded = false;
      await this.$store.dispatch('getAllClasses');
      this.classMap = this.$store.state.schema.classMap;
      this.loadOptions();
      this.updateActiveClassesByQuery();
      await this.updateFiltersByQuery();
      this.isLoaded = true;
    },
    async advancedSearch() {
      this.isSearching = true;
      await this.$store.dispatch('clearSearchResult');
      await this.$store.dispatch('advancedSearch', this.query);
      this.isSearching = false;
      this.results = this.$store.state.term.searchedResults;
      this.count = this.$store.state.term.searchedResultsCount || 0;
    },
    refreshSearchFilter() {
      this.activeFilters = [];
      setTimeout(() => {
        this.updateDefaultFiltersByClass(this.activeClasses);
      }, 1);
    },
    routeToNewSearch() {
      let query = {};
      this.activeFilters.forEach(filter => {
        if (!filter.value) return;
        if (
          typeof filter.value === 'object' &&
          !Array.isArray(filter.value) &&
          filter.type !== 'relationships'
        ) {
          for (let operator in filter.value) {
            query[`${filter.name}.${operator}`] = filter.value[operator];
          }
          return;
        }
        if (
          typeof filter.value === 'object' &&
          !Array.isArray(filter.value) &&
          filter.type === 'relationships'
        ) {
          for (let key in filter.value) {
            this.addToQuery(query, filter.value[key], `${filter.name}_${key}`);
          }
        } else {
          this.addToQuery(query, filter.value, filter.name);
        }
      });
      if (this.keyWord && this.keyWord !== '')
        query[`${this.PPT_NAME}.like`] = this.keyWord;
      if (this.activeClasses.length > 0)
        query.label = this.activeClasses.join();
      query.start = 0;
      query.end = 20;
      this.routeToPage('AdvancedSearch', query);
    },
    addToQuery(query, filterValue, filterName) {
      if (Array.isArray(filterValue)) {
        filterValue = filterValue.join();
      }
      if (query.hasOwnProperty(filterName)) {
        if (Array.isArray(query[filterName])) {
          query[filterName].push(filterValue);
        } else {
          query[filterName] = [query[filterName], filterValue];
        }
      } else {
        query[filterName] = filterValue;
      }
    },
    routeToTerm(termObject) {
      this.routeToPage('TermView', {}, { entryId: termObject.id }, true);
    },
    pageChanged(payload) {
      if (payload.pageSize != this.pageSize) {
        this.pageSize = payload.pageSize;
      }
      let query = this.deepCopy(this.query);
      query.start = payload.firstItem - 1;
      query.end = payload.lastItem;
      this.routeToPage('AdvancedSearch', query);
    },
    updateFilterValue(index, newValue) {
      this.activeFilters[index].value = newValue;
    },
    removeFilter(index) {
      this.activeFilters.splice(index, 1);
    },
    selectFilter(filterName) {
      const filterObj = this.findFilterInMap(filterName);
      this.activeFilters.push(filterObj);
    },
    findFilterInActiveList(filterName) {
      return this.activeFilters.findIndex(element => {
        return element.name === filterName;
      });
    },
    makeOptionsFromList(strlist) {
      if (strlist === undefined || strlist === '') return undefined;
      const list = strlist.split(',');
      let options = [];
      list.forEach(item => {
        options.push({ text: item, value: item });
      });
      options.sort((a, b) => (a.text > b.text ? 1 : -1));
      return options;
    },
    findFilterInMap(value) {
      let newFilter = { name: value };
      if (this.attributeMap.hasOwnProperty(value)) {
        const attribute = this.attributeMap[value];
        newFilter[this.PPT_TYPE] = attribute[this.PPT_TYPE];
        newFilter[this.PPT_DESCRIPTION] = attribute[this.PPT_DESCRIPTION];
        newFilter['options'] = this.makeOptionsFromList(
          attribute[this.PPT_PREDEFINED_LIST],
        );
      } else if (this.relationshipMap.hasOwnProperty(value)) {
        newFilter[this.PPT_TYPE] = 'relationships';
        newFilter[this.PPT_DESCRIPTION] = this.relationshipMap[value][
          this.PPT_DESCRIPTION
        ];
        newFilter[this.PPT_LABEL] = this.relationshipMap[value][this.PPT_LABEL];
      } else {
        return null;
      }
      return newFilter;
    },
    loadOptions() {
      this.relationshipMap = this.$store.state.schema.relationshipMap;
      this.attributeMap = this.$store.state.schema.attributeMap;
      this.loadClassOptions();
      this.loadFilterOptions();
      this.loadDownloadOptions();
    },
    loadClassOptions() {
      let classOptions = [];
      for (let key in this.classMap) {
        classOptions.push({
          value: key,
          text: this.decamelize(key),
        });
      }
      classOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
      this.classOptions = classOptions;
    },
    loadFilterOptions() {
      let filterOptions = [];
      for (let relationship in this.relationshipMap) {
        filterOptions.push({
          value: relationship,
          text: this.relationshipMap[relationship][this.PPT_DESCRIPTION],
        });
      }
      for (let attribute in this.attributeMap) {
        if (attribute === this.PPT_NAME) continue;
        filterOptions.push({
          value: attribute,
          text: this.attributeMap[attribute][this.PPT_DESCRIPTION],
        });
      }
      filterOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
      this.filterOptions = filterOptions;
    },
    loadDownloadOptions() {
      let downloadAttrOptions = [],
        downloadRelaOptions = [];
      for (let relationship in this.relationshipMap) {
        this.relationshipMap[relationship]['label'].forEach(element => {
          downloadRelaOptions.push({
            value: `${relationship}_${element}`,
            text: `${
              this.relationshipMap[relationship][this.PPT_DESCRIPTION]
            } - ${this.decamelize(element)}`,
          });
        });
      }
      for (let attribute in this.attributeMap) {
        if (attribute === this.PPT_NAME) continue;
        downloadAttrOptions.push({
          value: attribute,
          text: this.attributeMap[attribute][this.PPT_DESCRIPTION],
        });
      }
      downloadAttrOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
      downloadRelaOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
      this.downloadAttrOptions = downloadAttrOptions;
      this.downloadRelaOptions = downloadRelaOptions;
    },
    updateDefaultFiltersByClass(newClass) {
      newClass.forEach(className => {
        if (this.defaultFilter.hasOwnProperty(className)) {
          this.defaultFilter[className].forEach(filterName => {
            const filterIdx = this.findFilterInActiveList(filterName);
            if (filterIdx === -1) {
              const newFilter = this.findFilterInMap(filterName);
              if (newFilter) this.activeFilters.push(newFilter);
            }
          });
        }
      });
    },
    updateFilterDownloadOptions(newClass) {
      if (newClass.length === 0) {
        this.loadFilterOptions();
        this.loadDownloadOptions();
        return;
      }
      let filterOptions = [],
        downloadAttrOptions = [],
        downloadRelaOptions = [];
      let filterSet = new Set();
      let relationshipSet = new Set();
      this.activeClasses.forEach(className => {
        if (this.classMap.hasOwnProperty(className)) {
          let classObj = this.classMap[className];
          classObj[this.PPT_RELATIONSHIPS].forEach(relationship => {
            const name = relationship[this.PPT_NAME];
            if (!filterSet.has(name)) {
              filterOptions.push({
                value: name,
                text: relationship[this.PPT_DESCRIPTION],
              });
              filterSet.add(name);
            }
            const relationshipValue = `${relationship[this.PPT_NAME]}_${
              relationship[this.PPT_TARGET_CLASS]
            }`;
            if (!relationshipSet.has(relationshipValue)) {
              downloadRelaOptions.push({
                value: relationshipValue,
                text: `${
                  relationship[this.PPT_DESCRIPTION]
                } - ${this.decamelize(relationship[this.PPT_TARGET_CLASS])}`,
              });
              relationshipSet.add(relationshipValue);
            }
          });
          classObj[this.PPT_ATTRIBUTES].forEach(attribute => {
            const name = attribute[this.PPT_NAME];
            if (name === this.PPT_NAME) return;
            if (!filterSet.has(name)) {
              filterOptions.push({
                value: attribute[this.PPT_NAME],
                text: attribute[this.PPT_DESCRIPTION],
              });
              downloadAttrOptions.push({
                value: attribute[this.PPT_NAME],
                text: attribute[this.PPT_DESCRIPTION],
              });
              filterSet.add(name);
            }
          });
        }
      });
      filterOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
      downloadRelaOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
      downloadAttrOptions.sort((a, b) => (a.text > b.text ? 1 : -1));
      this.filterOptions = filterOptions;
      this.downloadRelaOptions = downloadRelaOptions;
      this.downloadAttrOptions = downloadAttrOptions;
    },
    async updateFiltersByQuery() {
      let query = this.$route.query;
      let relationshipIds = '';
      for (let filterName in query) {
        let operator = null;
        let filterValues = query[filterName];
        if (filterName.includes('_')) {
          const nameAndClass = filterName.split('_');
          filterName = nameAndClass[0];
        }
        if (filterName.includes('.')) {
          const nameAndOperator = filterName.split('.');
          filterName = nameAndOperator[0];
          operator = nameAndOperator[1];
        }
        if ([this.PPT_NAME, this.PPT_LABEL].includes(filterName)) continue;
        const newFilter = this.findFilterInMap(filterName);
        if (!newFilter) continue;
        if (Array.isArray(filterValues)) {
          filterValues.forEach(filterValue => {
            let filterCopy = Object.assign({}, newFilter);
            filterCopy.value = filterValue;
            this.activeFilters.push(filterCopy);
            if (newFilter.type === 'relationships')
              relationshipIds = `${relationshipIds},${filterValue}`;
          });
        } else {
          if (operator === 'gte') {
            filterValues = { [operator]: filterValues };
            if (query.hasOwnProperty(`${filterName}.lte`)) {
              filterValues['lte'] = query[`${filterName}.lte`];
            }
          }
          if (operator === 'lte') {
            if (query.hasOwnProperty(`${filterName}.gte`)) {
              continue;
            } else {
              filterValues = { [operator]: filterValues };
            }
          }
          newFilter.value = filterValues;
          this.activeFilters.push(newFilter);
          if (newFilter.type === 'relationships')
            relationshipIds = `${relationshipIds},${filterValues}`;
        }
      }
      await this.$store.dispatch(
        'getEntriesName',
        relationshipIds.substring(1),
      );
    },
    updateActiveClassesByQuery() {
      let query = this.$route.query;
      if (query.hasOwnProperty(this.PPT_LABEL)) {
        this.activeClasses = query[this.PPT_LABEL].split(',');
      } else if (Object.keys(query).length == 0) {
        this.activeClasses = ['article', 'video'];
      } else {
        this.activeClasses = [];
      }
    },
    showDownloadModal() {
      this.downloadModal = true;
    },
    async downloadResults() {
      this.isDownloading = true;
      const query_ = this.deepCopy(this.query);
      const targetField = [this.PPT_NAME].concat(
        this.downloadAttr,
        this.downloadRela,
      );
      query_['download'] = targetField.join(',');
      await this.$store.dispatch('downloadSearchResult', query_);
      this.isDownloading = false;
      this.downloadModal = false;
      window.open(this.$store.state.term.downloadURL);
    },
  },
  computed: {
    query() {
      return this.$route.query;
    },
    currentPage() {
      if (this.query.hasOwnProperty('start')) {
        return Math.floor(this.query.start / 20) + 1;
      } else {
        return 1;
      }
    },
  },
  watch: {
    async query() {
      await this.advancedSearch();
    },
    activeClasses(newClass) {
      this.updateFilterDownloadOptions(newClass);
      this.updateDefaultFiltersByClass(newClass);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/style/global.scss';
.long-form {
  height: 70vh;
}
.mds-section-actions-spacing {
  margin: 0 0 #{-$mds-space-1-x} 0;
}
.mds-list-group-spacing {
  padding: #{$mds-space-2-x} #{$mds-space-1-x} #{$mds-space-2-x} #{$mds-space-1-x};
}
.add-filter-select-spacing {
  margin: #{$mds-space-1-x} 0 #{$mds-space-1-x} 0;
}
</style>
