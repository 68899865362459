var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { style: _vm.containerSize }, [
    _c(
      "svg",
      { attrs: { width: _vm.settings.width, height: _vm.settings.height } },
      [
        _c(
          "g",
          _vm._l(_vm.links, function(link, linkIndex) {
            return _c("concept-hierarchy-link", {
              key: "" + link.target.id + linkIndex,
              attrs: {
                link: link,
                "node-width": _vm.nodeWidth,
                "node-height": _vm.nodeHeight,
                "link-stroke": _vm.linkStroke,
                "link-stroke-width": _vm.linkStrokeWidth
              }
            })
          }),
          1
        ),
        _c(
          "g",
          _vm._l(_vm.nodes, function(node, nodeIndex) {
            return _c("concept-hierarchy-node", {
              key: "" + node.id + nodeIndex,
              attrs: {
                node: node,
                "node-width": _vm.nodeWidth,
                "node-height": _vm.nodeHeight
              },
              nativeOn: {
                click: function($event) {
                  return _vm.select(node)
                }
              }
            })
          }),
          1
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }