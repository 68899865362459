var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _c(
        "div",
        { staticClass: "mds-site-navigation__actions" },
        [
          _c("mds-button", {
            attrs: { variation: "icon-only", icon: "remove" },
            on: { click: _vm.hideHiddenNav }
          })
        ],
        1
      ),
      _c(
        "mds-section",
        { attrs: { title: "Management" } },
        [
          _c(
            "mds-list-group",
            { attrs: { variation: "navigation" } },
            _vm._l(_vm.navList, function(item) {
              return _c("mds-list-group-item", {
                key: item.text,
                attrs: { text: item.text },
                on: {
                  "mds-list-item-clicked": function($event) {
                    return _vm.routeTo(item.route)
                  }
                }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }