var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.singleValue
        ? [
            _vm.type === "DateTime"
              ? _c("span", [_vm._v(" " + _vm._s(_vm.value.replace("T", " ")))])
              : _vm.type === "URL"
              ? _c("a", { attrs: { href: _vm.value } }, [
                  _vm._v(" " + _vm._s(_vm.value))
                ])
              : _vm.type === "Free Text"
              ? [_vm._v(" " + _vm._s(_vm.value ? _vm.value : "None") + " ")]
              : _c("span", [_vm._v(" " + _vm._s(_vm.value) + " ")])
          ]
        : !_vm.singleValue
        ? [
            _vm.name === "popularity" || _vm.name === "popularity180"
              ? _c("span", [
                  _vm._v(" " + _vm._s(_vm.value[_vm.value.length - 1]) + " ")
                ])
              : _vm._e(),
            _vm.name === "translation"
              ? _c(
                  "span",
                  [
                    _c(
                      "mds-button",
                      {
                        staticClass: "link",
                        attrs: { variation: "flat" },
                        on: {
                          click: function($event) {
                            return _vm.toggleTranslationDiv()
                          }
                        }
                      },
                      [_vm._v("Click to view")]
                    ),
                    _vm.isTranslationShow
                      ? _c("div", { staticClass: "mds-doc-typography" }, [
                          _c("ul", { staticStyle: { "list-style": "none" } }, [
                            _c(
                              "li",
                              { staticClass: "hide-marker" },
                              _vm._l(
                                _vm.buildTranslationList(_vm.value),
                                function(item) {
                                  return _c(
                                    "ul",
                                    {
                                      key: "" + item,
                                      staticStyle: { "list-style": "none" }
                                    },
                                    [_c("li", [_vm._v(_vm._s(item))])]
                                  )
                                }
                              ),
                              0
                            )
                          ])
                        ])
                      : _vm._e()
                  ],
                  1
                )
              : _c("span", [_vm._v(" " + _vm._s(_vm.value.join(", ")) + " ")])
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }