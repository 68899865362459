var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    [
      _vm.isLoading
        ? _c("mds-loader")
        : !_vm.isTermExist
        ? _c("div", [
            _vm._v("Term " + _vm._s(_vm.activeTerm) + " does not exist.")
          ])
        : _c(
            "mds-section",
            {
              attrs: {
                title: this.termObject.attributes.name,
                bold: "",
                border: "none"
              },
              scopedSlots: _vm._u([
                {
                  key: "mds-section-title-description",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "icon-placeholder" },
                        [
                          _c("AppIconTooltip", {
                            attrs: { icon: "pencil", tooltip: "edit term" },
                            on: { click: _vm.startEditing }
                          }),
                          _vm.termObject[_vm.PPT_LABEL] === "concept"
                            ? _c("AppIconTooltip", {
                                attrs: {
                                  icon: "group-row",
                                  tooltip: "go to Hierarchy"
                                },
                                on: { click: _vm.openHierarchy }
                              })
                            : _vm._e(),
                          _vm.termObject[_vm.PPT_ATTRIBUTES][
                            _vm.PPT_USE_CASE_TEXT
                          ]
                            ? _c("AppIconTooltipTextAndHover", {
                                attrs: {
                                  icon: "document",
                                  tooltip:
                                    _vm.termObject[_vm.PPT_ATTRIBUTES][
                                      _vm.PPT_USE_CASE_TEXT
                                    ],
                                  variation: "flat",
                                  text: _vm.PPT_USE_CASE_LABEL,
                                  flatButtonSize: "medium"
                                }
                              })
                            : _vm._e(),
                          _vm.termObject[_vm.PPT_ATTRIBUTES][
                            _vm.PPT_DEFINITION_TEXT
                          ]
                            ? _c("AppIconTooltipAndText", {
                                attrs: {
                                  icon: "paperclip-angle",
                                  tooltip:
                                    _vm.termObject[_vm.PPT_ATTRIBUTES][
                                      _vm.PPT_DEFINITION_TEXT
                                    ],
                                  variation: "flat",
                                  text: _vm.PPT_DEFINITION_LABEL,
                                  flatButtonSize: "medium"
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                }
              ])
            },
            [
              _vm.termObject.hasOwnProperty(_vm.PPT_ATTRIBUTES) &&
              _vm.termObject[_vm.PPT_ATTRIBUTES].hasOwnProperty(
                _vm.PPT_IDENTIFIER
              )
                ? _c("div", [
                    _c("span", { staticClass: "bold" }, [_vm._v("Identifer:")]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.termObject[_vm.PPT_ATTRIBUTES][_vm.PPT_IDENTIFIER]
                        ) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c("div", [
                _c("span", { staticClass: "bold" }, [_vm._v("Class:")]),
                _vm._v(
                  " " +
                    _vm._s(_vm.decamelize(_vm.termObject[_vm.PPT_LABEL])) +
                    " "
                )
              ]),
              _c("div", [
                _c("span", { staticClass: "bold" }, [_vm._v("Database ID:")]),
                _vm._v(" " + _vm._s(_vm.termObject[_vm.PPT_ID]))
              ]),
              _vm._l(_vm.attributeSchema, function(attribute, aIndex) {
                return _c("div", { key: aIndex }, [
                  ![_vm.PPT_NAME, _vm.PPT_IDENTIFIER].includes(
                    attribute[_vm.PPT_NAME]
                  )
                    ? _c(
                        "div",
                        [
                          _c("span", { staticClass: "bold" }, [
                            _vm._v(_vm._s(attribute["description"]) + ":")
                          ]),
                          _vm.termObject.hasOwnProperty(_vm.PPT_ATTRIBUTES) &&
                          _vm.termObject[_vm.PPT_ATTRIBUTES].hasOwnProperty(
                            attribute[_vm.PPT_NAME]
                          )
                            ? _c("TermViewAttribute", {
                                attrs: {
                                  value:
                                    _vm.termObject[_vm.PPT_ATTRIBUTES][
                                      attribute[_vm.PPT_NAME]
                                    ],
                                  type: attribute["type"],
                                  singleValue: attribute["singleValue"],
                                  name: attribute[_vm.PPT_NAME]
                                }
                              })
                            : _c("span", [_vm._v(" None ")])
                        ],
                        1
                      )
                    : _vm._e()
                ])
              }),
              _vm._l(_vm.relationshipSchema, function(relationship, index) {
                return _c(
                  "div",
                  { key: "" + relationship[_vm.PPT_NAME] + index },
                  [
                    _c("div", { staticClass: "bold" }, [
                      _vm._v(_vm._s(relationship[_vm.PPT_DESCRIPTION]) + ":")
                    ]),
                    relationship[_vm.PPT_NAME] === _vm.PPT_DISCUSSED_IN
                      ? _c(
                          "div",
                          [
                            _c("mds-select", {
                              attrs: { label: "Locale", options: _vm.locales },
                              model: {
                                value: _vm.selectedLocale,
                                callback: function($$v) {
                                  _vm.selectedLocale = $$v
                                },
                                expression: "selectedLocale"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "mds-doc-typography" },
                      _vm._l(relationship[_vm.PPT_TARGET_CLASSES], function(
                        label
                      ) {
                        return _c(
                          "ul",
                          {
                            key: "" + relationship[_vm.PPT_NAME] + label,
                            staticStyle: { "list-style": "none" }
                          },
                          [
                            _c(
                              "li",
                              {
                                class: {
                                  "hide-marker":
                                    relationship[_vm.PPT_TARGET_CLASSES]
                                      .length <= 1
                                }
                              },
                              [
                                relationship[_vm.PPT_TARGET_CLASSES].length > 1
                                  ? _c("div", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.decamelize(label)) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                _vm.termObject.hasOwnProperty(
                                  _vm.PPT_RELATIONSHIPS
                                ) &&
                                _vm.termObject[
                                  _vm.PPT_RELATIONSHIPS
                                ].hasOwnProperty(relationship[_vm.PPT_NAME]) &&
                                _vm.termObject[_vm.PPT_RELATIONSHIPS][
                                  relationship[_vm.PPT_NAME]
                                ].hasOwnProperty(label)
                                  ? _c(
                                      "div",
                                      [
                                        _vm._l(
                                          _vm.termObject[_vm.PPT_RELATIONSHIPS][
                                            relationship[_vm.PPT_NAME]
                                          ][label],
                                          function(
                                            relationshipItem,
                                            relationshipIndex
                                          ) {
                                            return _c(
                                              "ul",
                                              {
                                                key: relationshipIndex,
                                                staticStyle: {
                                                  "list-style": "none"
                                                }
                                              },
                                              [
                                                _c(
                                                  "li",
                                                  [
                                                    _c("TermViewRelationship", {
                                                      attrs: {
                                                        termObject: relationshipItem
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          }
                                        ),
                                        _vm.termObject[
                                          _vm.PPT_RELATIONSHIPS_NUMBER
                                        ][relationship[_vm.PPT_NAME]][label] >
                                        _vm.pageSize
                                          ? _c("mds-pagination", {
                                              attrs: {
                                                "show-items-info": "",
                                                "total-items":
                                                  _vm.termObject[
                                                    _vm.PPT_RELATIONSHIPS_NUMBER
                                                  ][relationship[_vm.PPT_NAME]][
                                                    label
                                                  ],
                                                pageSize: _vm.pageSize
                                              },
                                              on: {
                                                "mds-pagination-page-changed": function(
                                                  $event
                                                ) {
                                                  return _vm.pageChanged(
                                                    relationship[_vm.PPT_NAME],
                                                    label,
                                                    $event
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e()
                                      ],
                                      2
                                    )
                                  : _c("div", [
                                      _vm._v(" None "),
                                      relationship[_vm.PPT_NAME] ===
                                        "narrowerTerms" &&
                                      _vm.termObject[_vm.PPT_ATTRIBUTES][
                                        _vm.PPT_CLASSIFIED
                                      ]
                                        ? _c("span", [_vm._v(" (End Node) ")])
                                        : _vm._e(),
                                      relationship[_vm.PPT_NAME] ===
                                        "broaderTerms" &&
                                      _vm.termObject[_vm.PPT_ATTRIBUTES][
                                        _vm.PPT_CLASSIFIED
                                      ]
                                        ? _c("span", [_vm._v(" (Top Node) ")])
                                        : _vm._e()
                                    ])
                              ]
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ]
                )
              })
            ],
            2
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }