var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mds-label",
    {
      staticClass: "inline-text",
      attrs: { required: _vm.data.required, text: _vm.data.label }
    },
    [
      _vm.data.tooltip
        ? _c("AppIconTooltip", {
            staticClass: "margin-left-4",
            attrs: {
              variation: "icon-only",
              icon: "info-circle",
              size: "small",
              position: "right-center",
              tooltip: _vm.data.tooltip
            }
          })
        : _vm._e(),
      _c(
        "mds-input",
        _vm._b(
          {
            attrs: { "hidden-label": "" },
            on: {
              change: _vm.inputChanged,
              keydown: function($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                $event.preventDefault()
              }
            },
            model: {
              value: _vm.data.value,
              callback: function($$v) {
                _vm.$set(_vm.data, "value", $$v)
              },
              expression: "data.value"
            }
          },
          "mds-input",
          _vm.data,
          false
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }