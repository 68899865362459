var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    [
      _vm.isLoading || _vm.isLoadingAuth
        ? _c("mds-loader")
        : !_vm.hasAccess
        ? _c("div", [_vm._v("Sorry, you don't have write access.")])
        : _c("div", [
            !_vm.isCreateEntry && !_vm.isTermExist
              ? _c("div", [
                  _vm._v(
                    " Term " + _vm._s(_vm.activeTerm) + " does not exist. "
                  )
                ])
              : _c(
                  "div",
                  [
                    _c("mds-form", { attrs: { onsubmit: "return false" } }, [
                      _c(
                        "div",
                        { staticClass: "edit-term-form-area" },
                        [
                          _c("mds-select", {
                            attrs: {
                              name: "taxonomyClass",
                              label: "Primary Classification (Class)",
                              options: _vm.classOptions,
                              disabled: !_vm.isCreateEntry
                            },
                            on: { change: _vm.classSelected },
                            model: {
                              value: _vm.termObject["label"],
                              callback: function($$v) {
                                _vm.$set(_vm.termObject, "label", $$v)
                              },
                              expression: "termObject['label']"
                            }
                          }),
                          _c(
                            "mds-tabs",
                            {
                              staticClass: "edit-term-tab",
                              attrs: { size: "large" }
                            },
                            _vm._l(_vm.tabsContent, function(item, index) {
                              return _c("mds-tabs-item", {
                                key: index,
                                attrs: {
                                  active: item.active,
                                  id: item.id,
                                  text: item.text,
                                  target: item.target
                                },
                                nativeOn: {
                                  click: function($event) {
                                    return _vm.setActiveItem.apply(
                                      null,
                                      arguments
                                    )
                                  }
                                }
                              })
                            }),
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tab-placeholders",
                              attrs: { id: "tabEditRelationship" }
                            },
                            _vm._l(_vm.relationshipSchema, function(
                              relationship
                            ) {
                              return _c(
                                "TermEditRelationship",
                                _vm._b(
                                  {
                                    key:
                                      "" +
                                      relationship.name +
                                      relationship.targetClass,
                                    ref: "relationships",
                                    refInFor: true,
                                    on: {
                                      change: function($event) {
                                        return _vm.relationshipChanged(
                                          relationship,
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  "TermEditRelationship",
                                  relationship,
                                  false
                                )
                              )
                            }),
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "tab-placeholders",
                              staticStyle: { display: "none" },
                              attrs: { id: "tabEditAttribute" }
                            },
                            _vm._l(_vm.attributeSchema, function(
                              attribute,
                              attributeIdx
                            ) {
                              return _c(
                                "TermEditAttribute",
                                _vm._b(
                                  {
                                    key: "" + attribute.name + attributeIdx,
                                    ref: "attributes",
                                    refInFor: true,
                                    on: {
                                      change: function($event) {
                                        return _vm.attributeChanged(
                                          attribute.name,
                                          $event
                                        )
                                      }
                                    }
                                  },
                                  "TermEditAttribute",
                                  attribute,
                                  false
                                )
                              )
                            }),
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "edit-term-button-area" },
                        [
                          _c(
                            "mds-button-container",
                            [
                              _c(
                                "mds-button",
                                {
                                  attrs: {
                                    variation: "primary",
                                    type: "button",
                                    disabled:
                                      !_vm.isDirty ||
                                      _vm.isSubmiting ||
                                      _vm.isDeleting,
                                    loading: _vm.isSubmiting
                                  },
                                  on: { click: _vm.submit }
                                },
                                [_vm._v(" Submit ")]
                              ),
                              !_vm.isCreateEntry
                                ? _c(
                                    "mds-button",
                                    {
                                      staticClass: "dangerous-button",
                                      attrs: {
                                        variation: "primary",
                                        type: "button",
                                        disabled:
                                          _vm.isSubmiting || _vm.isDeleting,
                                        loading: _vm.isDeleting
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.toggleDelete = true
                                        }
                                      }
                                    },
                                    [_vm._v(" Delete Entry ")]
                                  )
                                : _vm._e(),
                              _c(
                                "mds-button",
                                {
                                  attrs: {
                                    disabled: _vm.isSubmiting || _vm.isDeleting
                                  },
                                  on: { click: _vm.cancelEditing }
                                },
                                [_vm._v(" Cancel ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
          ]),
      _c(
        "mds-modal",
        {
          attrs: { title: "Warning", "action-required": "" },
          scopedSlots: _vm._u([
            {
              key: "mds-modal-actions",
              fn: function() {
                return [
                  _c(
                    "mds-button-container",
                    { attrs: { "right-aligned": "" } },
                    [
                      _c(
                        "mds-button",
                        {
                          attrs: { variation: "secondary" },
                          on: {
                            click: function($event) {
                              _vm.toggleDelete = false
                            }
                          }
                        },
                        [_vm._v(" No, cancel ")]
                      ),
                      _c(
                        "mds-button",
                        {
                          attrs: { variation: "primary", type: "button" },
                          on: { click: _vm.deleteTerm }
                        },
                        [_vm._v(" Yes, continue ")]
                      )
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.toggleDelete,
            callback: function($$v) {
              _vm.toggleDelete = $$v
            },
            expression: "toggleDelete"
          }
        },
        [
          _vm._v(" Are you sure you want to permanently delete this entry? "),
          _vm.hasRelationship
            ? _c("div", [
                _vm._v(" All relationships of this entry will be deleted too! ")
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }