var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    {
      scopedSlots: _vm._u([
        {
          key: "mds-page-shell-vertical-nav",
          fn: function() {
            return [
              _c("ConceptHierarchyNav", {
                attrs: {
                  optionsList: _vm.optionsList,
                  nextTerms: _vm.nextTerms,
                  relatedTermsCache: _vm.relatedTermsCache
                },
                on: { change: _vm.selectConcept }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { attrs: { id: "overlay" } },
        [_c("mds-loader", { attrs: { id: "loader" } })],
        1
      ),
      _vm._l(_vm.treeData, function(options, index) {
        return _c("ConceptHierarchyGraph", {
          key: index,
          attrs: { treeData: _vm.treeData[index] },
          on: { change: _vm.selectConcept }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }