var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _c(
        "mds-button",
        {
          staticClass: "icon-button-text",
          attrs: {
            variation: _vm.variation,
            type: "button",
            id: _vm.id,
            icon: _vm.icon,
            disabled: _vm.disabled,
            size: _vm.size,
            "flat-button-icon-size": _vm.flatButtonSize
          },
          on: {
            click: _vm.toggleTooltipModal,
            blur: function($event) {
              _vm.toggleTooltip = false
            },
            mouseover: function($event) {
              _vm.toggleInfoTooltip = true
            },
            mouseleave: function($event) {
              _vm.toggleInfoTooltip = false
            }
          }
        },
        [_vm._v(_vm._s(_vm.text) + " ")]
      ),
      _c(
        "mds-tooltip",
        {
          attrs: {
            "triggered-by": _vm.id,
            position: _vm.position,
            size: _vm.size
          },
          model: {
            value: _vm.toggleInfoTooltip,
            callback: function($$v) {
              _vm.toggleInfoTooltip = $$v
            },
            expression: "toggleInfoTooltip"
          }
        },
        [_vm._v(" " + _vm._s(_vm.infoTooltip) + " ")]
      ),
      _c(
        "mds-tooltip",
        {
          attrs: {
            "triggered-by": _vm.id,
            position: _vm.position,
            size: _vm.size
          },
          model: {
            value: _vm.toggleTooltip,
            callback: function($$v) {
              _vm.toggleTooltip = $$v
            },
            expression: "toggleTooltip"
          }
        },
        [_c("span", { domProps: { innerHTML: _vm._s(_vm.tooltip) } })]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }