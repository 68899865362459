var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mds-module-container",
    { staticClass: "relationship-margin" },
    [
      _c(
        "mds-section",
        {
          attrs: {
            collapsible: true,
            expanded: true,
            bold: true,
            title: _vm.cardTitle,
            size: 7,
            borderPosition: "bottom"
          },
          scopedSlots: _vm._u([
            {
              key: "mds-section-title-description",
              fn: function() {
                return [
                  _vm.required
                    ? _c("mds-button", {
                        staticClass: "mds-label__requird-indicator",
                        attrs: { variation: "flat", type: "button", text: "*" }
                      })
                    : _vm._e(),
                  _vm.classDescription !== null
                    ? _c(
                        "mds-button",
                        {
                          staticClass: "info__button",
                          attrs: {
                            variation: "icon-only",
                            icon: "info-circle",
                            size: "small",
                            type: "button",
                            id: _vm.targetClass
                          },
                          on: {
                            mouseover: function($event) {
                              _vm.toggle = true
                            },
                            mouseleave: function($event) {
                              _vm.toggle = false
                            }
                          }
                        },
                        [_vm._v("Open Popover")]
                      )
                    : _vm._e(),
                  _vm.classDescription !== null
                    ? _c(
                        "mds-popover",
                        {
                          attrs: {
                            "triggered-by": _vm.targetClass,
                            "title-hidden": "",
                            position: "bottom-right"
                          },
                          model: {
                            value: _vm.toggle,
                            callback: function($$v) {
                              _vm.toggle = $$v
                            },
                            expression: "toggle"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.classDescription) + " ")]
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            },
            {
              key: "mds-section-supplemental-content",
              fn: function() {
                return [
                  _vm.error
                    ? _c(
                        "div",
                        { staticClass: "mds-input__field-error-wrapper" },
                        _vm._l(_vm.errorText, function(text, index) {
                          return _c(
                            "span",
                            { key: index, staticClass: "mds-field-error" },
                            [_vm._v(" " + _vm._s(text) + " ")]
                          )
                        }),
                        0
                      )
                    : _vm._e()
                ]
              },
              proxy: true
            }
          ])
        },
        [
          _vm.relationships
            ? _c(
                "mds-list-group",
                _vm._l(_vm.relationships, function(item, index) {
                  return _c(
                    "mds-list-group-item",
                    { key: index, staticClass: "full-width" },
                    [
                      _c("mds-button", {
                        attrs: { variation: "flat", text: item.name },
                        on: {
                          click: function($event) {
                            return _vm.routeToTerm($event, item)
                          }
                        }
                      }),
                      !_vm.readOnly
                        ? _c("mds-button", {
                            staticClass: "float-right",
                            attrs: { variation: "icon-only", icon: "remove" },
                            on: {
                              click: function($event) {
                                return _vm.removed(item)
                              }
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                }),
                1
              )
            : _vm._e(),
          _vm.count > _vm.pageSize
            ? _c("mds-pagination", {
                attrs: {
                  "show-items-info": "",
                  "total-items": _vm.count,
                  pageSize: _vm.pageSize
                },
                on: {
                  "mds-pagination-page-changed": function($event) {
                    return _vm.pageChanged($event)
                  }
                }
              })
            : _vm._e(),
          _vm.removedList.length > 0
            ? _c(
                "div",
                [
                  _c("h4", { staticClass: "h4-margin" }, [
                    _vm._v("Relationships to be removed:")
                  ]),
                  _c(
                    "mds-list-group",
                    _vm._l(_vm.removedList, function(item, index) {
                      return _c(
                        "mds-list-group-item",
                        { key: index, staticClass: "full-width" },
                        [
                          _c("mds-button", {
                            attrs: { variation: "flat", text: item.name },
                            on: {
                              click: function($event) {
                                return _vm.routeToTerm($event, item)
                              }
                            }
                          }),
                          _c("mds-button", {
                            staticClass: "float-right",
                            attrs: { variation: "icon-only", icon: "remove" },
                            on: {
                              click: function($event) {
                                return _vm.cancelRemove(item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.readOnly
            ? _c("TermSearchField", {
                staticClass: "add-search-field",
                attrs: {
                  onSelectRoute: false,
                  labelFilter: new Set([_vm.targetClass]),
                  placeholder: "Search and add..."
                },
                on: {
                  selected: function($event) {
                    return _vm.added($event)
                  }
                }
              })
            : _vm._e(),
          _vm.addedList.length > 0
            ? _c(
                "div",
                [
                  _c("h4", { staticClass: "h4-margin" }, [
                    _vm._v("Relationships to be added:")
                  ]),
                  _c(
                    "mds-list-group",
                    _vm._l(_vm.addedList, function(item, index) {
                      return _c(
                        "mds-list-group-item",
                        { key: index, staticClass: "full-width" },
                        [
                          _c("mds-button", {
                            attrs: { variation: "flat", text: item.name },
                            on: {
                              click: function($event) {
                                return _vm.routeToTerm($event, item)
                              }
                            }
                          }),
                          _c("mds-button", {
                            staticClass: "float-right",
                            attrs: { variation: "icon-only", icon: "remove" },
                            on: {
                              click: function($event) {
                                return _vm.cancelAdd(item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }