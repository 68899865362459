var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("mds-search-field", {
        attrs: { id: _vm.id, placeholder: _vm.placeholder },
        nativeOn: {
          keyup: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            return _vm.routeToAdvancedSearch.apply(null, arguments)
          }
        },
        model: {
          value: _vm.searchField,
          callback: function($$v) {
            _vm.searchField = $$v
          },
          expression: "searchField"
        }
      }),
      _vm.isResultsShown
        ? _c(
            "mds-search-results",
            {
              directives: [
                {
                  name: "click-outside",
                  rawName: "v-click-outside",
                  value: _vm.hideResults,
                  expression: "hideResults"
                }
              ],
              staticClass: "search-results",
              class: { "search-results--small": _vm.size === "small" }
            },
            [
              _vm.onSelectRoute
                ? _c("mds-button", {
                    staticClass: "float-right",
                    attrs: {
                      variation: "flat",
                      size: "small",
                      text: "Create New Term"
                    },
                    on: { click: _vm.routeToCreateNewTerm }
                  })
                : _vm._e(),
              _vm.isSearching
                ? _c("mds-loader", { attrs: { size: "small" } })
                : _c(
                    "mds-search-results-section",
                    { attrs: { title: "" } },
                    [
                      _vm.results && _vm.results.length == 0
                        ? _c("mds-empty-state", {
                            attrs: {
                              title: "No Results",
                              message: "Try refining your search string."
                            }
                          })
                        : _c(
                            "mds-list-group",
                            _vm._l(_vm.results, function(item, index) {
                              return _c("AppTermSummary", {
                                key: index,
                                attrs: { termObject: item },
                                on: {
                                  click: function($event) {
                                    return _vm.selectResult(item)
                                  }
                                }
                              })
                            }),
                            1
                          )
                    ],
                    1
                  )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }