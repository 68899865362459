var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mds-modal",
    {
      attrs: {
        title: _vm.isNew ? "Add Attribute" : "Edit Attribute",
        width: "600px",
        "action-required": ""
      },
      scopedSlots: _vm._u([
        {
          key: "mds-modal-actions",
          fn: function() {
            return [
              _c(
                "mds-button-container",
                { attrs: { "right-aligned": "" } },
                [
                  _c(
                    "mds-button",
                    {
                      attrs: { variation: "secondary", type: "button" },
                      on: { click: _vm.cancel }
                    },
                    [_vm._v(" Cancel ")]
                  ),
                  _c(
                    "mds-button",
                    {
                      attrs: { variation: "primary", type: "button" },
                      on: { click: _vm.save }
                    },
                    [_vm._v(" Save ")]
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.toggle,
        callback: function($$v) {
          _vm.toggle = $$v
        },
        expression: "toggle"
      }
    },
    [
      _c(
        "mds-form",
        { attrs: { onsubmit: "return false" } },
        [
          _vm.isNew
            ? _c(
                "mds-checkbox",
                {
                  staticClass: "check-box-margin",
                  model: {
                    value: _vm.isCreate,
                    callback: function($$v) {
                      _vm.isCreate = $$v
                    },
                    expression: "isCreate"
                  }
                },
                [_vm._v(" Create a new attribute ")]
              )
            : _vm._e(),
          !_vm.isNew || _vm.isCreate
            ? _c(
                "mds-input",
                _vm._b(
                  {
                    attrs: { disabled: !_vm.isNew },
                    on: {
                      keydown: function($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        ) {
                          return null
                        }
                        $event.preventDefault()
                      }
                    },
                    model: {
                      value: _vm.name.value,
                      callback: function($$v) {
                        _vm.$set(_vm.name, "value", $$v)
                      },
                      expression: "name.value"
                    }
                  },
                  "mds-input",
                  _vm.name,
                  false
                )
              )
            : _c(
                "mds-select",
                _vm._b(
                  {
                    on: { change: _vm.selectAttribute },
                    model: {
                      value: _vm.nameSelect.value,
                      callback: function($$v) {
                        _vm.$set(_vm.nameSelect, "value", $$v)
                      },
                      expression: "nameSelect.value"
                    }
                  },
                  "mds-select",
                  _vm.nameSelect,
                  false
                )
              ),
          _c(
            "mds-input",
            _vm._b(
              {
                on: {
                  keydown: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    $event.preventDefault()
                  },
                  change: _vm.assignName
                },
                model: {
                  value: _vm.description.value,
                  callback: function($$v) {
                    _vm.$set(_vm.description, "value", $$v)
                  },
                  expression: "description.value"
                }
              },
              "mds-input",
              _vm.description,
              false
            )
          ),
          _c(
            "mds-select",
            _vm._b(
              {
                model: {
                  value: _vm.type.value,
                  callback: function($$v) {
                    _vm.$set(_vm.type, "value", $$v)
                  },
                  expression: "type.value"
                }
              },
              "mds-select",
              _vm.type,
              false
            )
          ),
          _c(
            "mds-checkbox",
            {
              staticClass: "check-box-margin",
              model: {
                value: _vm.required.value,
                callback: function($$v) {
                  _vm.$set(_vm.required, "value", $$v)
                },
                expression: "required.value"
              }
            },
            [_vm._v(" Required ")]
          ),
          _c(
            "mds-checkbox",
            {
              staticClass: "check-box-margin",
              model: {
                value: _vm.singleValue.value,
                callback: function($$v) {
                  _vm.$set(_vm.singleValue, "value", $$v)
                },
                expression: "singleValue.value"
              }
            },
            [_vm._v(" Single Value ")]
          ),
          _c(
            "mds-checkbox",
            {
              staticClass: "check-box-margin",
              model: {
                value: _vm.adminOnly.value,
                callback: function($$v) {
                  _vm.$set(_vm.adminOnly, "value", $$v)
                },
                expression: "adminOnly.value"
              }
            },
            [_vm._v(" Admin Only ")]
          ),
          _c(
            "mds-checkbox",
            {
              staticClass: "check-box-margin",
              model: {
                value: _vm.readOnly.value,
                callback: function($$v) {
                  _vm.$set(_vm.readOnly, "value", $$v)
                },
                expression: "readOnly.value"
              }
            },
            [_vm._v(" Read Only ")]
          ),
          _c(
            "AppInputTags",
            _vm._b(
              {
                attrs: {
                  disabled: !["Free Text", "Structured Text"].includes(
                    this.type.value
                  )
                },
                on: { change: _vm.predefinedListChanged }
              },
              "AppInputTags",
              _vm.predefinedList,
              false
            )
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }