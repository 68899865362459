var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    {
      scopedSlots: _vm._u([
        {
          key: "mds-page-shell-vertical-nav",
          fn: function() {
            return [_c("WorkingDashboardNav")]
          },
          proxy: true
        }
      ])
    },
    [
      _vm.isLoadingAuth
        ? _c("mds-loader")
        : !_vm.hasAccess
        ? _c("div", [_vm._v("Sorry, you don't have write access.")])
        : _c(
            "div",
            { staticClass: "container" },
            [
              _c("h3", { staticClass: "title" }, [_vm._v(" Batch Mapping ")]),
              _c(
                "mds-form",
                { attrs: { onsubmit: "return false" } },
                [
                  _c(
                    "mds-layout-grid",
                    [
                      _c(
                        "mds-row",
                        {
                          staticClass: "search-row",
                          attrs: { "align-vertical": "flex-start" }
                        },
                        [
                          _c(
                            "mds-col",
                            [
                              _c("mds-combo-box", {
                                attrs: {
                                  label: "All entries in these classes",
                                  multiple: "",
                                  dataSet: _vm.classOptions
                                },
                                model: {
                                  value: _vm.labels,
                                  callback: function($$v) {
                                    _vm.labels = $$v
                                  },
                                  expression: "labels"
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "mds-col",
                            [
                              _c("div", { staticClass: "bold label-spacing" }, [
                                _vm._v(
                                  " and are included in below collections "
                                )
                              ]),
                              _c("TermSearchField", {
                                attrs: {
                                  placeholder: "Search and add collections...",
                                  onSelectRoute: false,
                                  labelFilter: new Set(["collection"])
                                },
                                on: { selected: _vm.addCollection }
                              }),
                              _vm._l(_vm.selectedCollections, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "mds-tag-container",
                                  { key: index, staticClass: "tag-spacing" },
                                  [
                                    _c(
                                      "mds-tag",
                                      {
                                        attrs: {
                                          removable: "",
                                          "on-light": true
                                        },
                                        on: {
                                          "mds-tag-removed": function($event) {
                                            return _vm.removeCollection(index)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          ),
                          _c(
                            "mds-col",
                            [
                              _c("div", { staticClass: "bold label-spacing" }, [
                                _vm._v(" will be mapped to below concepts ")
                              ]),
                              _c("TermSearchField", {
                                attrs: {
                                  placeholder: "Search and add concepts...",
                                  onSelectRoute: false,
                                  labelFilter: new Set(["concept"])
                                },
                                on: { selected: _vm.addConcept }
                              }),
                              _vm._l(_vm.selectedConcepts, function(
                                item,
                                index
                              ) {
                                return _c(
                                  "mds-tag-container",
                                  { key: index, staticClass: "tag-spacing" },
                                  [
                                    _c(
                                      "mds-tag",
                                      {
                                        attrs: {
                                          removable: "",
                                          "on-light": true
                                        },
                                        on: {
                                          "mds-tag-removed": function($event) {
                                            return _vm.removeConcept(index)
                                          }
                                        }
                                      },
                                      [_vm._v(" " + _vm._s(item.name) + " ")]
                                    )
                                  ],
                                  1
                                )
                              })
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "mds-button-container",
                    [
                      _c("mds-button", {
                        staticClass: "tag-spacing",
                        attrs: {
                          text: "Preview",
                          type: "button",
                          disabled: _vm.isPreviewDisabled || _vm.isPreviewing,
                          loading: _vm.isPreviewing
                        },
                        on: { click: _vm.preview }
                      }),
                      _c("mds-button", {
                        staticClass: "tag-spacing",
                        attrs: {
                          text: "Submit",
                          type: "button",
                          variation: "primary",
                          disabled: _vm.isSubmitDisabled || _vm.isSubmitting,
                          loading: _vm.isSubmitting
                        },
                        on: { click: _vm.submit }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }