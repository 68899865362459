var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "app-masthead",
    { attrs: { showSearchField: false } },
    [
      _c(
        "mds-layout-grid",
        [
          _c(
            "mds-row",
            {
              staticClass: "search-row",
              attrs: {
                "align-horizontal": "center",
                "align-vertical": "center"
              }
            },
            [
              _c(
                "mds-col",
                { staticClass: "max-width" },
                [
                  _c("TermSearchField", {
                    attrs: { placeholder: "Search and create new..." }
                  }),
                  _c("mds-button", {
                    staticClass: "float-right",
                    attrs: {
                      variation: "flat",
                      size: "small",
                      text: "Advanced Search"
                    },
                    on: { click: _vm.routeToAdvancedSearch }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }