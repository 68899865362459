var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("mds-button", {
        attrs: { text: _vm.termObject.name, variation: "flat" },
        on: { click: _vm.routeToTerm }
      }),
      _vm.termObject.label == _vm.REL_ARTICLE ||
      _vm.termObject.label == _vm.REL_VIDEO
        ? [_vm._v(_vm._s(this.displayDate))]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }