// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".dangerous-button__taxonomy {\n  background-color: #ff0000 !important;\n}\n.bold__taxonomy {\n  font-weight: 600;\n}\n.float-right__taxonomy {\n  float: right;\n}\n.full-width .mds-list-group__item-text__taxonomy {\n  width: 100%;\n}\n.full-width .mds-layout-grid__row__taxonomy {\n  margin: 0 !important;\n}\n.table-button .mds-icon__taxonomy {\n  height: 20px !important;\n  width: 20px !important;\n}\n.inline-text .mds-label__text__taxonomy {\n  display: inline-block;\n}\n.table-cell-wrap .mds-data-table__cell__taxonomy {\n  white-space: nowrap;\n}\n.transparent-class-filter .mds-select__input__taxonomy {\n  width: 120px;\n  color: #5e5e5e;\n  -webkit-box-shadow: none;\n          box-shadow: none;\n  background-color: transparent !important;\n}\n.transparent-class-filter .mds-select__input__taxonomy:disabled {\n  -webkit-box-shadow: none;\n          box-shadow: none;\n}\n.transparent-class-filter .mds-select__input__taxonomy:hover, .transparent-class-filter .mds-select__input__taxonomy:focus {\n  -webkit-box-shadow: none;\n          box-shadow: none;\n  color: #1e1e1e;\n}", ""]);
// Exports
exports.locals = {
	"dangerous-button": "dangerous-button__taxonomy",
	"bold": "bold__taxonomy",
	"float-right": "float-right__taxonomy",
	"mds-list-group__item-text": "mds-list-group__item-text__taxonomy",
	"mds-layout-grid__row": "mds-layout-grid__row__taxonomy",
	"mds-icon": "mds-icon__taxonomy",
	"mds-label__text": "mds-label__text__taxonomy",
	"mds-data-table__cell": "mds-data-table__cell__taxonomy",
	"mds-select__input": "mds-select__input__taxonomy"
};
module.exports = exports;
