var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "mds-label",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.unFocus,
          expression: "unFocus"
        }
      ],
      staticClass: "inline-text",
      attrs: {
        size: _vm.size,
        required: _vm.required,
        "required-text": _vm.requiredText,
        text: _vm.label,
        "hidden-label": _vm.hiddenLabel
      },
      nativeOn: {
        click: function($event) {
          $event.preventDefault()
          return _vm.setFocus.apply(null, arguments)
        }
      }
    },
    [
      _vm.tooltip !== ""
        ? _c("mds-button", {
            attrs: {
              id: "tooltip-trigger",
              variation: "icon-only",
              icon: "info-circle",
              size: "small"
            },
            nativeOn: {
              mouseover: function($event) {
                _vm.tooltipToggle = true
              },
              mouseleave: function($event) {
                _vm.tooltipToggle = false
              },
              focus: function($event) {
                _vm.tooltipToggle = true
              }
            }
          })
        : _vm._e(),
      _vm.tooltip !== ""
        ? _c(
            "mds-tooltip",
            {
              attrs: {
                "triggered-by": "tooltip-trigger",
                position: "right-center"
              },
              model: {
                value: _vm.tooltipToggle,
                callback: function($$v) {
                  _vm.tooltipToggle = $$v
                },
                expression: "tooltipToggle"
              }
            },
            [_vm._v(" " + _vm._s(_vm.tooltip) + " ")]
          )
        : _vm._e(),
      _c(
        "div",
        {
          class: [_vm.$style["mds-combo-box"], _vm.classObject, _vm.sizeClass],
          attrs: { disabled: _vm.disabled }
        },
        [
          _c(
            "div",
            {
              class: _vm.$style["mds-combo-box__multiple-select"],
              attrs: { tabindex: this.disabled ? -1 : 0 }
            },
            [
              _c(
                "div",
                {
                  class: [
                    _vm.$style["mds-combo-box__selected-list"],
                    _vm.focusClass
                  ]
                },
                [
                  _c(
                    "span",
                    _vm._l(_vm.selectedItems, function(item, index) {
                      return _c("mds-tag", {
                        key: index,
                        class: _vm.$style["mds-combo-box__selection-tag"],
                        attrs: {
                          text: item,
                          "on-light": true,
                          removable: "",
                          size: _vm.tagSize
                        },
                        on: {
                          "mds-tag-removed": function($event) {
                            return _vm.deleteTags(index)
                          }
                        }
                      })
                    }),
                    1
                  ),
                  _c(
                    "span",
                    { class: _vm.$style["mds-combo-box__flex-container"] },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.newTerm,
                            expression: "newTerm"
                          }
                        ],
                        ref: "input",
                        class: _vm.$style["mds-combo-box__input"],
                        style: _vm.styleObject,
                        attrs: {
                          type: "text",
                          disabled: _vm.disabled,
                          tabindex: this.disabled ? -1 : 0,
                          placeholder: _vm.placeholder
                        },
                        domProps: { value: _vm.newTerm },
                        on: {
                          keydown: [
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "enter",
                                  13,
                                  $event.key,
                                  "Enter"
                                )
                              ) {
                                return null
                              }
                              $event.preventDefault()
                              return _vm.addTag.apply(null, arguments)
                            },
                            function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k(
                                  $event.keyCode,
                                  "tab",
                                  9,
                                  $event.key,
                                  "Tab"
                                )
                              ) {
                                return null
                              }
                              return _vm.handleTab.apply(null, arguments)
                            }
                          ],
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.newTerm = $event.target.value
                          }
                        }
                      })
                    ]
                  )
                ]
              )
            ]
          )
        ]
      ),
      _vm.error
        ? _c(
            "div",
            { staticClass: "mds-input__field-error-wrapper-dp" },
            _vm._l(_vm.errorText, function(text, index) {
              return _c(
                "span",
                { key: index, staticClass: "mds-field-error" },
                [_vm._v(" " + _vm._s(text) + " ")]
              )
            }),
            0
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }