var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "attribute-margin" },
    [
      _vm.isTranslation
        ? _c("TranslationAdd", {
            on: { "translation-add": _vm.translationChanged }
          })
        : _vm._e(),
      _vm.inputData && !_vm.isTextArea
        ? _c(
            "AppTextBox",
            _vm._b(
              {
                attrs: { data: _vm.inputData },
                on: { change: _vm.inputChanged }
              },
              "AppTextBox",
              _vm.inputData,
              false
            )
          )
        : _vm._e(),
      _vm.inputData && _vm.isTextArea
        ? _c(
            "AppTextArea",
            _vm._b(
              {
                attrs: { data: _vm.inputData },
                on: { change: _vm.inputChanged }
              },
              "AppTextArea",
              _vm.inputData,
              false
            )
          )
        : _vm._e(),
      _vm.multiInputData
        ? _c(
            "AppInputTags",
            _vm._b(
              { on: { change: _vm.multiInputChanged } },
              "AppInputTags",
              _vm.multiInputData,
              false
            )
          )
        : _vm._e(),
      _vm.selectData
        ? _c(
            "mds-select",
            _vm._b(
              {
                on: { change: _vm.selectChanged },
                model: {
                  value: _vm.selectData.value,
                  callback: function($$v) {
                    _vm.$set(_vm.selectData, "value", $$v)
                  },
                  expression: "selectData.value"
                }
              },
              "mds-select",
              _vm.selectData,
              false
            )
          )
        : _vm._e(),
      _vm.multiSelectData
        ? _c(
            "mds-combo-box",
            _vm._b(
              {
                attrs: { multiple: "" },
                on: { input: _vm.multiSelectChanged },
                model: {
                  value: _vm.multiSelectData.value,
                  callback: function($$v) {
                    _vm.$set(_vm.multiSelectData, "value", $$v)
                  },
                  expression: "multiSelectData.value"
                }
              },
              "mds-combo-box",
              _vm.multiSelectData,
              false
            )
          )
        : _vm._e(),
      _vm.dateData
        ? _c(
            "AppDatePicker",
            _vm._b(
              { on: { selected: _vm.dateChanged } },
              "AppDatePicker",
              _vm.dateData,
              false
            )
          )
        : _vm._e(),
      _vm.boolData
        ? _c(
            "AppBooleanSelector",
            _vm._b(
              { on: { change: _vm.boolChanged } },
              "AppBooleanSelector",
              _vm.boolData,
              false
            )
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }