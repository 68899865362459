var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    [
      _c(
        "mds-section",
        { attrs: { title: "Working Dashboard" } },
        [
          _c(
            "mds-list-group",
            { attrs: { variation: "navigation" } },
            [
              _c("mds-list-group-item", {
                attrs: { text: "Mapping Status" },
                on: {
                  "mds-list-item-clicked": function($event) {
                    return _vm.routeToPage("WorkingDashboardStatus")
                  }
                }
              }),
              _vm._l(_vm.pages, function(item) {
                return _c("mds-list-group-item", {
                  key: item,
                  attrs: { text: item },
                  on: {
                    "mds-list-item-clicked": function($event) {
                      _vm.routeToPage(
                        "WorkingDashboard",
                        {},
                        { className: item.split(" ")[1].toLowerCase() }
                      )
                    }
                  }
                })
              }),
              _c("mds-list-group-item", {
                attrs: { text: "Batch Mapping" },
                on: {
                  "mds-list-item-clicked": function($event) {
                    return _vm.routeToPage("BatchMapping")
                  }
                }
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }