var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("mds-list-group-item", {
    attrs: {
      text: _vm.termObject.name,
      "metadata-below": _vm.resultMetadata()
    },
    on: { "mds-list-item-clicked": _vm.click }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }